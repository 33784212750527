import React, { useEffect, useRef,useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { PrismicRichText } from "@prismicio/react";

import {downloadMedia, getButtonCustomStyles, getFontSize} from "../../assets/js";
import useWindowSize from "../../hooks/useWindowSize";

// constants
import { COLOR } from "../../constants/colors";
import CustomLink from "../../components/CustomLink";

// import Swiper core and required modules
import { Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import {AnchorLink} from "../../components/AnchorLink";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import { useConsent } from '../../components/ConsentContext';
import { setBuyNowClickedEvent, setDownloadClickedEvent } from '../../assets/js/data-layer';
import * as images from "../../assets/images/icons";
import YouTubeModal from '../../components/YouTubeModal';

export const Event = ({ slice }) => {
    const { width } = useWindowSize()
    const playerRef = useRef()

    const getEventDate = (event) => {
        let start_date = event?.data?.start_date;
        if(!start_date) return '';
        let time = event?.data?.time;

        if(time){
            let datePart = new Date(start_date).toISOString().split('T')[0];
            let timePart = new Date(time).toISOString().split('T')[1];
            return new Date(`${datePart}T${timePart}`);
        }
        var newDateObj = new Date(start_date);
        newDateObj.setHours(23);   // 11 PM
        newDateObj.setMinutes(59); // 59 minutes
        newDateObj.setSeconds(59);  // 59 seconds
        return new Date(newDateObj);
    }

    //Query for all document
    const allPrismicMctEventsData = useStaticQuery(allPrismicMctEventsPage);

    //Separate future and past events
    const currentDate = new Date();
    var pastEvents = allPrismicMctEventsData.allPrismicMctEventsPage.nodes.filter(event =>  !event?.data?.show_coming_soon && getEventDate(event) < currentDate);
    var upcomingEvents = allPrismicMctEventsData.allPrismicMctEventsPage.nodes.filter(event => event?.data?.show_coming_soon || getEventDate(event) >= currentDate);

    const sectionId = slice.primary.section_id?.text || ''
    const anchorLink = slice.primary.anchor_link?.text || ''

    // Toggles
    const showPreviousEvents = slice.primary.previous_events || false;

    const chooseAllEvents = slice.primary.choose_events == 'ALL'? true : false;
    if(!chooseAllEvents){
        pastEvents = slice.items?.filter(item => !item.event_link?.document.data.show_coming_soon && getEventDate(item.event_link?.document) < currentDate).map(item => item.event_link.document); // Chose Event Bug Resolve When We Chose Select From List Option
        upcomingEvents = slice.items?.filter(item => item.event_link?.document.data.show_coming_soon || getEventDate(item.event_link?.document) >= currentDate).map(item => item.event_link.document);
    }

    //Filter by event type
    const eventTypeFilter = slice.primary.event_filter
    pastEvents = pastEvents?.filter(data => eventTypeFilter === 'None' ? true : data.data.event_type === eventTypeFilter).sort((a, b) => {
        if (a.data?.show_coming_soon && !b.data?.show_coming_soon) { return 1; }
        else if (!a.data?.show_coming_soon && b.data?.show_coming_soon) { return -1; }
        else if (!a.data?.show_coming_soon && !b.data?.show_coming_soon) { return new Date(a.data?.start_date) - new Date(b.data?.start_date); }
        else { return 0; }
    })
    upcomingEvents = upcomingEvents?.filter(data => eventTypeFilter === 'None' ? true : data.data.event_type === eventTypeFilter).sort((a, b) => {
        if (a.data?.show_coming_soon && !b.data?.show_coming_soon) { return 1; }
        else if (!a.data?.show_coming_soon && b.data?.show_coming_soon) { return -1; }
        else if (!a.data?.show_coming_soon && !b.data?.show_coming_soon) { return new Date(a.data?.start_date) - new Date(b.data?.start_date); }
        else { return 0; }
    })


    // Text
    const featuredSectionTitle = slice.primary.title?.text || ''
    const upcomingEventsTitle = slice.primary.upcoming_events_title?.text || ''
    const previousEventsTitle = slice.primary.previous_events_title?.text

    const backgroundColor = slice.primary.background_color
    const textColor = slice.primary.text_color
    const badgeColor = slice.primary.badge_color

    const marginTop= slice.primary?.margin_top || '5'
    const marginBottom= slice.primary?.margin_bottom || '5'

    const options = { month: 'long', day: 'numeric', timeZone: 'UTC' };

    const eventContainerStyles = {
        backgroundColor: `${backgroundColor}`,
        color: `${textColor}`,
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`
    }

    // CTA Styles
    const buttonColorSet = {
        color: slice.primary.cta_button_text_color,
        bg: slice.primary.cta_button_bg_color,
        bgT: slice.primary.cta_button_bg_color_transparency,
        border: slice.primary.cta_button_border_color,
        colorHover: slice.primary.cta_button_text_color_hover,
        bgHover: slice.primary.cta_button_bg_color_hover,
        bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
        borderHover: slice.primary.cta_button_border_color_hover,
        borderRadius: slice.primary.cta_button_border_radius || "0",
    }

    const {ctaButtonStyle} = getButtonCustomStyles(buttonColorSet)

    // CTA Styles
    // const ctaMobButtonStyle = {
    //     color: slice.primary.cta_mobile_button_text_color || COLOR.WHITE,
    //     backgroundColor: slice.primary.cta_mobile_button_bg_color || COLOR.BLACK,
    // }

    // image
    // const image = {
    //     src: width < 768 ? slice.primary.banner?.thumbnails?.mobile.url : slice.primary.banner?.url,
    //     alt: slice.primary.banner?.alt || ''
    // }


    // swiper component
    const navigationUpcoming = {
        nextEl: '.event-section__carousel-arrow--next', prevEl: '.event-section__carousel-arrow--prev'
    }
    const navigationPast = {
        nextEl: '.event-section__carousel-arrow--next-next-events', prevEl: '.event-section__carousel-arrow--prev-prev-events'
    }

    const swiperBreakPoints = {
        0: {
          slidesPerView: 1,
          spaceBetween:32
        }, 768: {
          slidesPerView: 2,
          spaceBetween:32
        }, 992: {
          slidesPerView: 2,
          spaceBetween:32
        }, 1200: {
          slidesPerView: 3,
          spaceBetween:42
        }, 1500: {
          slidesPerView: 3,
          spaceBetween:55
        },
      };

    const formatdate = (date) =>{
        if (!date) {
            return '';
        }
        let formattedDate = date?.toLocaleDateString('en-US', options);
        if(!formattedDate) return
        let [month, day] = formattedDate.split(' ');
        let capitalizedMonth = month.toUpperCase();

        return `${capitalizedMonth} ${day}`;
    }

//     useEffect(() => {
//         if (!playerRef?.current) return

//         playerRef.current.addEventListener('suspend', () => {
//         playerRef.current?.play()
//         });

//     }, [])

//     const onCTABtnClick = (url, name, consent) =>{
//         const dataLayer = { url, name: name.toLowerCase()}
//         setBuyNowClickedEvent(dataLayer,consent)
//       }
   const consentAccepted = useConsent()
   const [show, setShow] = useState(false)
   const [modalDetails, setModalDetails] = useState({})
    const onCloseBtnClick=() => {
        setShow(false)
    }
    const onPlayBtnClick = (modalDetails) => {
        setShow(true)
        setModalDetails(modalDetails)
    }

    return(
        <section className='event-container' style={eventContainerStyles} id={`event-${sectionId}`}>
            { anchorLink && <AnchorLink link={anchorLink}/> }

            <div className="featured-section">
                <div className="container">
                    {featuredSectionTitle && <p className="featured-description" style={{color:`${textColor}`}}>{featuredSectionTitle}</p>}
                    <div className="session-heading">
                        <h4 style={{color:`${textColor}`}}>{upcomingEventsTitle}</h4>
                    </div>
                    { width >= 768?
                        <div className='event-section'>
                            <Swiper modules={[Navigation]} navigation={navigationUpcoming} initialSlide={0} speed={500}
                                breakpoints={swiperBreakPoints} >

                                    {upcomingEvents.map((item,index)=> {
                                            const cardBadgeText = item?.data?.badge?.text;
                                            const showComingSoon = item?.data?.show_coming_soon;
                                            const cardStartingDate = item?.data?.start_date? new Date(item?.data?.start_date): null;
                                            const formattedStartDate = formatdate(cardStartingDate);
                                            const cardImage = item?.data?.image;
                                            const cardTitle = item?.data?.title;
                                            const cardLocation = item?.data?.venue;
                                            const cardVenueAddress = item?.data?.venue_address;
                                            const cardDescription = item?.data?.description;
                                            const cardButtonText = item?.data?.cta_button_text ;
                                            // CTA Button Link
                                            const ctaButtonLink =  item.data.cta_button_link?.url || ''
                                            const ctaButtonDownloadLink =  item.data.cta_download_button_file?.url || ''
                                            // CTA Button
                                            const ctaButtonText = item.data.cta_button_text?.text || ''
                                            const ctaDownloadButtonFileName = item.data.cta_download_file_name || ''
                                            const showCTAButton = item.data.show_cta_button
                                            // Links
                                            const isDownloadLink = item.data.cta_download_button
                                            const isTargetBlank = item.data.cta_button_open_in_new_tab
                                            const target = isTargetBlank ? '_blank' : '_self'
                                            const contentTypeImage = item?.data?.content_type
                                            const githubVideoFilename = item?.data?.github_video_filename_desktop;
                                            const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
                                            const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''
                                            const githubVideoThumbnailDesktop=item?.data?.github_video_thumbnail_desktop;
                                            const modalDetails = {
                                                url: video
                                            }
                                            return (
                                            <SwiperSlide key={index} >
                                            {/* <div className='event-section'> */}
                                            <div className="event-div swiper-no-swiping">
                                                    <div className="event-head">
                                                        {cardBadgeText && <span className="event-tag" style={{background:`${badgeColor}`}}>{cardBadgeText}</span>}
                                                        <span className="event-date">{showComingSoon? "COMING SOON": formattedStartDate}</span>
                                                    </div>
                                                        {contentTypeImage&&
                                                            <div className="event-img">
                                                                <img src={cardImage.url} alt={cardImage.alt} />
                                                            </div>
                                                        }
                                                        {!contentTypeImage&&
                                                            <div className="video-item-img ">
                                                                <div className="video-item-play-btn">
                                                                    <img src={images.playVideoBtnWebp} alt="Play Button" className="play-btn" onClick={() => onPlayBtnClick(modalDetails)} />
                                                                </div>
                                                                <img className="event-video-thumb" src={githubVideoThumbnailDesktop?.url} alt={githubVideoThumbnailDesktop?.alt} />
                                                            </div>
                                                        }
                                                    <div className="event-title">
                                                        <span className="title-text">{cardTitle.text}</span>
                                                        <span className="title-location">{cardLocation.text}{cardVenueAddress.text ? `, ${cardVenueAddress.text}`: ''}</span>
                                                    </div>
                                                    <div className="event-description">{cardDescription.text}</div>
                                                    <div className="event-action">
                                                    {showCTAButton && !isDownloadLink && <a href={ctaButtonLink}  className="event-btn"
                                                            style={ctaButtonStyle} target={target}>
                                                                { ctaButtonText}
                                                                { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
                                                            </a> }

                                                            {showCTAButton &&  isDownloadLink && <button  className="event-btn" style={ctaButtonStyle}
                                                                onClick={()=>downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)}
                                                            >
                                                                {ctaButtonText}
                                                                <FontAwesomeIcon icon={faDownload} className='ml-2'/>
                                                            </button>
                                                            }
                                                    </div>
                                                </div>
                                            {/* </div> */}
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>

                            <button className="event-section__carousel-arrow event-section__carousel-arrow--prev">
                                <span className="visually-hidden">Prev</span>
                                <FontAwesomeIcon icon={faArrowLeft} className="fa-solid" size="2xl" />
                            </button>
                            <button className="event-section__carousel-arrow event-section__carousel-arrow--next">
                                <span className="visually-hidden">Next</span>
                                <FontAwesomeIcon icon={faArrowRight} className="fa-solid" size="2xl" />
                            </button>

                        </div>
                        :
                        <>
                            <div className='event-section'>
                                {upcomingEvents.map((item,index)=> {
                                                const cardBadgeText = item.data.badge?.text ;
                                                const showComingSoon = item?.data?.show_coming_soon;
                                                const cardStartingDate = item.data.start_date? new Date(item.data.start_date): null;
                                                const formattedStartDate = formatdate(cardStartingDate);
                                                const cardImage = item.data.image;
                                                const cardTitle = item.data.title;
                                                const cardLocation = item.data.venue;
                                                const cardVenueAddress = item?.data?.venue_address;
                                                const cardDescription = item.data.description;
                                                const cardButtonText = item.data.cta_button_text ;
                                                // CTA Button Link
                                                const ctaButtonLink =  item.data.cta_button_link?.url || ''
                                                const ctaButtonDownloadLink =  item.data.cta_download_button_file?.url || ''
                                                // CTA Button
                                                const ctaButtonText = item.data.cta_button_text?.text || ''
                                                const ctaDownloadButtonFileName = item.data.cta_download_file_name || ''
                                                const showCTAButton = item.data.show_cta_button
                                                // Links
                                                const isDownloadLink = item.data.cta_download_button
                                                const isTargetBlank = item.data.cta_button_open_in_new_tab
                                                const target = isTargetBlank ? '_blank' : '_self'
                                                const contentTypeImage = item?.data?.content_type
                                                const githubVideoFilename = item?.data?.github_video_filename_mobile                                                ;
                                                const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
                                                const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''
                                                const githubVideoThumbnailMobile=item?.data?.github_video_thumbnail_mobile;
                                                const modalDetails = {
                                                    url: video
                                                }

                                                return (
                                                    <div key={"upcoming" + index} className="event-div swiper-no-swiping">
                                                        <div className="event-head">
                                                        {cardBadgeText && <span className="event-tag" style={{background:`${badgeColor}`}}>{cardBadgeText}</span>}
                                                            <span className="event-date">{showComingSoon? "COMING SOON" : formattedStartDate}</span>
                                                        </div>
                                                        {/*<div className="event-img"><img src={cardImage.url} alt={cardImage.alt} /></div>*/}
                                                        {contentTypeImage&&
                                                            <div className="event-img">
                                                                <img src={cardImage.url} alt={cardImage.alt} />
                                                            </div>
                                                        }
                                                        {!contentTypeImage&&
                                                            <div className="video-item-img">
                                                                <div className="video-item-play-btn">
                                                                    <img src={images.playVideoBtnWebp} alt="Play Button" className="play-btn" onClick={() => onPlayBtnClick(modalDetails)} />
                                                                </div>
                                                                <img className="event-video-thumb" src={githubVideoThumbnailMobile?.url} alt={githubVideoThumbnailMobile?.alt} />
                                                            </div>
                                                        }
                                                        <div className="event-title">
                                                            <span className="title-text">{cardTitle.text}</span>
                                                            <span className="title-location">{cardLocation.text}{cardVenueAddress.text ? `, ${cardVenueAddress.text}`: ''}</span>
                                                        </div>
                                                        <div className="event-description">{cardDescription.text}</div>
                                                        <div className="event-action">
                                                                {showCTAButton && !isDownloadLink && <a href={ctaButtonLink}  className="event-btn"
                                                                    style={ctaButtonStyle} target={target}>
                                                                        { ctaButtonText}
                                                                        { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
                                                                    </a> }

                                                                {showCTAButton && isDownloadLink && <button  className="event-btn" style={ctaButtonStyle}
                                                                    onClick={()=>downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)}
                                                                >
                                                                    {ctaButtonText}
                                                                    <FontAwesomeIcon icon={faDownload} className='ml-2'/>
                                                                </button>
                                                        }
                                                        </div>
                                                    </div>
                                                );
                                            })}
                            </div>
                        </>
                    }
                </div>
            </div>

            {showPreviousEvents && pastEvents.length >0 &&
                <div className="featured-section">
                    <div className="container">
                        <div className="session-heading">
                            <h4 style={{color:`${textColor}`}}>{previousEventsTitle}</h4>
                        </div>
                        { width >= 768?
                            <div className='event-section'>
                                <Swiper modules={[Navigation]} navigation={navigationPast} initialSlide={0} speed={500}
                                    breakpoints={swiperBreakPoints} spaceBetween={32} >

                                        {pastEvents.map((item,index)=> {
                                                const cardBadgeText = item?.data?.badge?.text;
                                                const cardStartingDate = item?.data?.start_date? new Date(item?.data?.start_date): null;
                                                const formattedStartDate = formatdate(cardStartingDate);
                                                const cardImage = item?.data?.image;
                                                const cardTitle = item?.data?.title;
                                                const cardLocation = item?.data?.venue;
                                                const cardVenueAddress = item?.data?.venue_address;
                                                const cardDescription = item?.data?.description;
                                                const cardButtonText = item?.data?.cta_button_text ;
                                                // CTA Button Link
                                                const ctaButtonLink =  item.data.cta_button_link?.url || ''
                                                const ctaButtonDownloadLink =  item.data.cta_download_button_file?.url || ''
                                                // CTA Button
                                                const ctaButtonText = item.data.cta_button_text?.text || ''
                                                const ctaDownloadButtonFileName = item.data.cta_download_file_name || ''
                                                const showCTAButton = item.data.show_cta_button
                                                // Links
                                                const isDownloadLink = item.data.cta_download_button
                                                const isTargetBlank = item.data.cta_button_open_in_new_tab
                                                const target = isTargetBlank ? '_blank' : '_self'
                                                const contentTypeImage = item?.data?.content_type
                                                const githubVideoFilename = item?.data?.github_video_filename_desktop;
                                                const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
                                                const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : '';
                                                const githubVideoThumbnailDesktop=item?.data?.github_video_thumbnail_desktop;
                                                const modalDetails = {
                                                    url: video
                                                }
                                            return (
                                                <SwiperSlide key={index} >
                                                {/* <div className='event-section'> */}
                                                <div className="event-div swiper-no-swiping">
                                                        <div className="event-head">
                                                            {cardBadgeText && <span className="event-tag" style={{background:`${badgeColor}`}}>{cardBadgeText}</span>}
                                                            <span className="event-date">{formattedStartDate}</span>
                                                        </div>
                                                        {/*<div className="event-img"><img src={cardImage.url} alt={cardImage.alt} /></div>*/}
                                                        {contentTypeImage&&
                                                            <div className="event-img">
                                                                <img src={cardImage.url} alt={cardImage.alt} />
                                                            </div>
                                                        }
                                                        {!contentTypeImage&&
                                                            <div className="video-item-img">
                                                                <div className="video-item-play-btn">
                                                                    <img src={images.playVideoBtnWebp} alt="Play Button" className="play-btn " onClick={() => onPlayBtnClick(modalDetails)} />
                                                                </div>
                                                                <img className="event-video-thumb" src={githubVideoThumbnailDesktop?.url} alt={githubVideoThumbnailDesktop?.alt} />
                                                            </div>
                                                        }
                                                        <div className="event-title">
                                                            <span className="title-text">{cardTitle.text}</span>
                                                            <span className="title-location">{cardLocation.text}{cardVenueAddress.text ? `, ${cardVenueAddress.text}`: ''}</span>
                                                        </div>
                                                        <div className="event-description">{cardDescription.text}</div>
                                                        <div className="event-action">
                                                        {showCTAButton && !isDownloadLink && <a href={ctaButtonLink}  className="event-btn"
                                                                style={ctaButtonStyle} target={target}>
                                                                    { ctaButtonText}
                                                                    { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
                                                                </a> }

                                                                {showCTAButton &&  isDownloadLink && <button  className="event-btn" style={ctaButtonStyle}
                                                                    onClick={()=>downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)}
                                                                >
                                                                    {ctaButtonText}
                                                                    <FontAwesomeIcon icon={faDownload} className='ml-2'/>
                                                                </button>
                                                        }
                                                        </div>
                                                    </div>
                                                {/* </div> */}
                                                </SwiperSlide>
                                            );
                                        })}
                                </Swiper>

                                <button className="event-section__carousel-arrow event-section__carousel-arrow--prev-prev-events">
                                    <span className="visually-hidden">Prev</span>
                                    <FontAwesomeIcon icon={faArrowLeft} className="fa-solid" size="2xl" />
                                </button>
                                <button className="event-section__carousel-arrow event-section__carousel-arrow--next-next-events">
                                    <span className="visually-hidden">Next</span>
                                    <FontAwesomeIcon icon={faArrowRight} className="fa-solid" size="2xl" />
                                </button>

                            </div>
                            :
                            <div className='event-section'>
                                {pastEvents.map((item,index)=> {
                                                const cardBadgeText = item?.data?.badge?.text;
                                                const cardStartingDate = item?.data?.start_date? new Date(item?.data?.start_date): null;
                                                const formattedStartDate = formatdate(cardStartingDate);
                                                const cardImage = item?.data?.image;
                                                const cardTitle = item?.data?.title;
                                                const cardLocation = item?.data?.venue;
                                                const cardVenueAddress = item?.data?.venue_address;
                                                const cardDescription = item?.data?.description;
                                                const cardButtonText = item?.data?.cta_button_text ;
                                                // CTA Button Link
                                                const ctaButtonLink =  item.data.cta_button_link?.url || ''
                                                const ctaButtonDownloadLink =  item.data.cta_download_button_file?.url || ''
                                                // CTA Button
                                                const ctaButtonText = item.data.cta_button_text?.text || ''
                                                const ctaDownloadButtonFileName = item.data.cta_download_file_name || ''
                                                const showCTAButton = item.data.show_cta_button
                                                // Links
                                                const isDownloadLink = item.data.cta_download_button
                                                const isTargetBlank = item.data.cta_button_open_in_new_tab
                                                const target = isTargetBlank ? '_blank' : '_self'
                                                const contentTypeImage = item?.data?.content_type
                                                const githubVideoFilename = item?.data?.github_video_filename_mobile;
                                                const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
                                                const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''
                                                const githubVideoThumbnailMobile=item?.data?.github_video_thumbnail_mobile;
                                                const modalDetails = {
                                                    url: video
                                                }

                                            return (
                                                <div  key={"past" + index} className="event-div swiper-no-swiping">
                                                        <div className="event-head">
                                                            {cardBadgeText && <span className="event-tag" style={{background:`${badgeColor}`}}>{cardBadgeText}</span>}
                                                            <span className="event-date">{formattedStartDate}</span>
                                                        </div>
                                                        {/*<div className="event-img"><img src={cardImage.url} alt={cardImage.alt} /></div>*/}
                                                        {contentTypeImage&&
                                                            <div className="event-img">
                                                                <img src={cardImage.url} alt={cardImage.alt} />
                                                            </div>
                                                        }
                                                        {!contentTypeImage&&
                                                            <div className="video-item-img ">
                                                                <div className="video-item-play-btn">
                                                                    <img src={images.playVideoBtnWebp} alt="Play Button" className="play-btn " onClick={() => onPlayBtnClick(modalDetails)} />
                                                                </div>
                                                                <img className="event-video-thumb" src={githubVideoThumbnailMobile?.url} alt={githubVideoThumbnailMobile?.alt} />
                                                            </div>
                                                        }
                                                        <div className="event-title">
                                                            <span className="title-text">{cardTitle.text}</span>
                                                            <span className="title-location">{cardLocation.text}{cardVenueAddress.text ? `, ${cardVenueAddress.text}`: ''}</span>
                                                        </div>
                                                        <div className="event-description">{cardDescription.text}</div>
                                                        <div className="event-action">
                                                        {showCTAButton && !isDownloadLink && <a href={ctaButtonLink}  className="event-btn"
                                                                style={ctaButtonStyle} target={target}>
                                                                    { ctaButtonText}
                                                                    { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
                                                                </a> }

                                                                {showCTAButton &&  isDownloadLink && <button  className="event-btn" style={ctaButtonStyle}
                                                                    onClick={()=>downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)}
                                                                >
                                                                    {ctaButtonText}
                                                                    <FontAwesomeIcon icon={faDownload} className='ml-2'/>
                                                                </button>
                                                        }
                                                        </div>
                                                    </div>
                                            );
                                        })}
                            </div>
                        }
                    </div>
                </div>
            }
            { show && modalDetails?.url && <YouTubeModal modalDetails={modalDetails?.url} closeModal={onCloseBtnClick}/> }

        </section>
    );
}

export const fragment = graphql`
    fragment PageDataBodyEvent on PrismicGenericPageDataBodyEvent {
        id
        primary {
            section_id{
              text
            }
            anchor_link{
              text
            }
            background_color
            badge_color
            choose_events
            cta_button_bg_color
            cta_button_bg_color_hover
            cta_button_bg_color_transparency
            cta_button_bg_color_hover_transparency
            cta_button_border_color
            cta_button_border_color_hover
            cta_button_border_radius
            cta_button_text_color
            cta_button_text_color_hover
            layout_variations
            margin_bottom
            margin_top
            event_filter
            upcoming_events_title {
              richText
              text
            }
            previous_events
            previous_events_title {
              richText
              text
            }
            text_color
            title {
              richText
              text
            }
          }
        items {
            event_link {
                document {
                    ...on PrismicMctEventsPage {
                        data {
                            content_type
                            github_video_filename_desktop
                            github_video_filename_mobile
                            github_video_thumbnail_desktop {
                                alt
                                url
                            }
                            github_video_thumbnail_mobile {
                                url
                                alt
                            }
                            cta_button_open_in_new_tab
                            cta_download_button
                            cta_download_file_name
                            event_type
                            show_cta_button
                            show_coming_soon
                            start_date
                            end_date
                            time
                            image {
                                alt
                                url
                            }
                            venue {
                                richText
                                text
                            }
                            title {
                                richText
                                text
                            }
                            description {
                                richText
                                text
                            }
                            cta_button_text {
                                richText
                                text
                            }
                            cta_button_link {
                                url
                            }
                            badge {
                                richText
                                text
                            }
                            venue_address {
                                richText
                                text
                            }
                        }
                    }
                }
            }
        }
    }
`

export const allPrismicMctEventsPage = graphql`
  query {
    allPrismicMctEventsPage {
      nodes {
        data {
          content_type
          github_video_filename_desktop
          github_video_filename_mobile
          github_video_thumbnail_desktop {
            alt
            url
          }
          github_video_thumbnail_mobile {
            url
            alt
          }
          cta_button_open_in_new_tab
          cta_download_button
          cta_download_file_name
          event_type
          show_cta_button
          show_coming_soon
          start_date
          end_date
          time
          image {
            alt
            url
          }
          venue {
            richText
            text
          }
          title {
            richText
            text
          }
          description {
            richText
            text
          }
          cta_button_text {
            richText
            text
          }
          cta_button_link {
            url
          }
          badge {
            richText
            text
          }
          venue_address {
            richText
            text
          }
        }
      }
    }
    prismicGenericPage {
      data {
        email_subscription_pop_up
        header_navigation
      }
    }
  }
`